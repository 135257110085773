import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { FederationService } from '@app/federation/federation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;

  is_provisory_registration: boolean = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private federationService: FederationService
  ) {}

  ngOnInit() {
    this.federationService.detail({ federation_id: this.credentialsService.federation_id }).subscribe((res: any) => {
      this.is_provisory_registration = res.data.is_provisory;
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get usertype(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.type : null;
  }

  myProfile() {
    //routerLink="/my-profile/" routerLinkActive="active"
    this.router.navigate(['/my-profile']);
  }
}
