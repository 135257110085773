import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { GroupsService } from '../groups.service';
import { DataService } from '@app/@helpers/data.service';
import { ToastService } from '@app/@shared/toast/toast.service';
import { finalize } from 'rxjs/operators';
import { untilDestroyed } from '@app/@core';
import { AppService } from '@app/app.service';
import { ConfirmModalComponent } from '@app/@shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-workshops',
  templateUrl: './workshops.component.html',
  styleUrls: ['./workshops.component.scss'],
})
export class WorkshopsComponent implements OnInit {
  @Input() group_id: any;
  error: any;
  form!: FormGroup;
  isLoading = false;
  obj: any;
  config: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private groupsService: GroupsService,
    private appService: AppService,
    private toastService: ToastService,
    private ngbModal: NgbModal
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.config = this.appService.config();
      console.log(this.config);

      for (let formModule of this.config.workshop_defination) {
        this.form.addControl('workshop_' + formModule.id, new FormControl(''));
      }

      this.form.patchValue({ group_id: this.group_id });
      this.detail();
    });
  }

  detail() {
    this.groupsService.workshopDetail({ group_id: this.group_id }).subscribe((res: any) => {
      if (res.success) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let field_id = 'workshop_' + res.data[i]['workshop_id'];
            console.log(field_id + ' - ' + res.data[i]);
            this.form.patchValue({ [field_id]: res.data[i] ? true : false });
          }
        }
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      group_id: ['', Validators.required],
    });
  }

  save() {
    let formData = this.form.value;

    let cnt = 0;
    for (let formModule of this.config.workshop_defination) {
      let field_id = 'workshop_' + formModule.id;
      console.log(this.form.value[field_id]);
      if (this.form.value[field_id]) {
        cnt++;
      }
      //this.form.addControl("workshop_"+formModule.id,  new FormControl(''));
    }

    if (cnt == 0) {
      this.toastService.show('Select atleast one', { classname: 'bg-danger text-light', delay: 5000 });
      return false;
    }

    if (cnt > 6) {
      this.toastService.show('Max. 6 workshops are allowed', { classname: 'bg-danger text-light', delay: 5000 });
      return false;
    }

    this.isLoading = true;

    console.log(formData);
    //return;
    //let save$ = this.groupsService.editEventRegistration(formData);
    //if(this.group_id){
    let save$ = this.groupsService.editWorkshops(formData);
    //}

    save$
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.detail();
          this.toastService.show('Saved sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.error = false;
        } else {
          this.error = res.message;
        }
      });
  }

  ngOnDestroy() {}
}
