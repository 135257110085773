import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { I18nModule } from '@app/i18n';

import { DateAdapterService } from '@app/@helpers/date-adapter.service';
import { TimeAdapterService } from '@app/@helpers/time-adapter.service';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { SharedModule } from '@shared';
import { GroupProvisoryRoutingModule } from './group-provisory-routing.module';
import { DateFormatterService } from '@app/@helpers/date-formatter.service';
import { GroupProvisoryListComponent } from './list/list.component';
import { GroupProvisoryComponent } from './group/group.component';
import { GroupProvisoryAddComponent } from './add/add.component';

@NgModule({
  declarations: [GroupProvisoryListComponent, GroupProvisoryComponent, GroupProvisoryAddComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    GroupProvisoryRoutingModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    NgbModule,
    I18nModule,
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: DateAdapterService },
    { provide: NgbDateParserFormatter, useClass: DateFormatterService },
    { provide: NgbTimeAdapter, useClass: TimeAdapterService },
  ],
})
export class GroupProvisoryModule {}
