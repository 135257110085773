<div class="container">
  <div class="row">
    <div class="col-6">
      <h3 *ngIf="!group_id">Add group</h3>
      <h3 *ngIf="group_id && detailObj">Edit group {{ detailObj.name }}</h3>
    </div>
    <div class="col-6 align-self-center">
      <button class="btn btn-info float-right" (click)="back()"><i class="fa fa-chevron-left"></i> Back</button>
    </div>
  </div>
  <div class="border-top mt-3 mb-4"></div>

  <ul ngbNav [activeId]="route.fragment | async" class="nav-tabs">
    <li [ngbNavItem]="link.fragment" *ngFor="let link of links" [disabled]="!group_id">
      <a ngbNavLink routerLink="." [fragment]="link.fragment">{{ link.title }}</a>
    </li>
  </ul>

  <router-outlet></router-outlet>
  <div *ngIf="fragment == 'group'">
    <app-group group_id="{{ group_id }}"></app-group>
  </div>

  <div *ngIf="fragment == 'event_registration'">
    <app-participants group_id="{{ group_id }}"></app-participants>
  </div>

  <div *ngIf="fragment == 'participant-registration'">
    <app-athlete-registration group_id="{{ group_id }}"></app-athlete-registration>
  </div>

  <div *ngIf="fragment == 'show-performances'">
    <app-city-performance group_id="{{ group_id }}"></app-city-performance>
  </div>

  <div *ngIf="fragment == 'workshops'">
    <app-workshops group_id="{{ group_id }}"></app-workshops>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
