import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbDateParserFormatter, NgbDateAdapter, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';

import { GroupsRoutingModule } from './groups-routing.module';
import { SharedModule } from '@shared';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { AddComponent } from './add/add.component';
import { ListComponent } from './list/list.component';
import { GroupComponent } from './group/group.component';
import { AthleteRegistrationComponent } from './athlete-registration/athlete-registration.component';
import { ParticipantsComponent } from './participants/participants.component';
import { CityPerformanceComponent } from './city-performance/city-performance.component';
import { UploadAtheleteComponent } from './athlete-registration/upload-athelete/upload-athelete.component';
import { AddAthleteComponent } from './athlete-registration/add-athlete/add-athlete.component';
import { ListAthletesComponent } from './athlete-registration/list-athletes/list-athletes.component';
import { DateFormatterService } from '@app/@helpers/date-formatter.service';
import { DateAdapterService } from '@app/@helpers/date-adapter.service';
import { TimeAdapterService } from '@app/@helpers/time-adapter.service';
import { WorkshopsComponent } from './workshops/workshops.component';

@NgModule({
  declarations: [
    ListComponent,
    AddComponent,
    GroupComponent,
    AthleteRegistrationComponent,
    ParticipantsComponent,
    CityPerformanceComponent,
    UploadAtheleteComponent,
    AddAthleteComponent,
    ListAthletesComponent,
    WorkshopsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    GroupsRoutingModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    NgbModule,
    I18nModule,
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: DateAdapterService },
    { provide: NgbDateParserFormatter, useClass: DateFormatterService },
    { provide: NgbTimeAdapter, useClass: TimeAdapterService },
  ],
})
export class GroupsModule {}
