import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FederationService } from '../federation.service';
import { DataService } from '@app/@helpers/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/@shared';
import { ToastService } from '@app/@shared/toast/toast.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-federation',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  data: any;
  toasts: any[] = [];
  isLoading = false;
  constructor(
    route: ActivatedRoute,
    private router: Router,
    private credentialsService: CredentialsService,
    private federationService: FederationService,
    private dataService: DataService,
    private ngbModal: NgbModal,
    private toastService: ToastService
  ) {
    route.params.subscribe((val) => {
      this.list();
    });
  }

  ngOnInit() {
    // Only admin has access to this page
    if (this.credentialsService.isAuthenticated() && this.credentialsService.usertype !== 'ADMIN') {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  get usertype(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.type : null;
  }

  list() {
    this.federationService.list().subscribe((res: any) => {
      this.data = res.data;
      console.log(this.data);
    });
  }

  add() {
    this.router.navigate(['/federation/add']);
  }

  edit(item: any) {
    this.dataService.set(item);
    this.router.navigate(['/federation/edit/' + item.id]);
  }

  delete(id: any) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Confirm';
    modalRef.componentInstance.description = 'Are you sure you want to delete?';
    modalRef.result.then((result) => {
      if (result) {
        this.federationService.delete(id).subscribe((res: any) => {
          this.data = res.data;
          this.toastService.show('Deleted sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.list();
        });
      }
    });
  }

  groupList(item: any) {
    console.log('ITEM: ', item);

    this.dataService.setByKey('federation_id', item.id);
    this.dataService.setByKey('federation_name', item.name);

    if (item.is_provisory == 1) {
      this.router.navigate(['/group-provisory']);
    } else {
      this.router.navigate(['/groups']);
    }
  }

  downloadReport(item: any, report: any) {
    this.isLoading = true;
    let report_name = '';

    this.federationService.getReport(report, { federation_id: item.id }).subscribe((res: any) => {
      if (report == 'musics_contest' || report == 'musics_show_performances') {
        report_name = item.acronym + '_' + report + '.zip';
        const blob = new Blob([res], { type: 'application/zip' });
        saveAs(blob, item.acronym + '_' + report + '.zip');
        this.isLoading = false;
      } else {
        report_name = report + '.xlsx';
        if (item !== -1) report_name = item.acronym + '_' + report + '.xlsx';
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, report_name);
        this.isLoading = false;
      }

      //let fileUrl = window.URL.createObjectURL(blob);
      //saveAs(blob, report + '.xlsx');

      //if(res.success){
      // let fileName = report;
      // const blob = new Blob([res.blob()], { type : 'application/vnd.ms.excel' });
      // const file = new File([blob], fileName + '.xlsx', { type: 'application/vnd.ms.excel' });
      // console.log(file);
      // saveAs(file);
      //console.log(res);
      //return res;
      // }
    });
  }
}
