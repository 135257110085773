<div class="container">
  <div class="row">
    <div class="col-6">
      <h3 translate>My profile</h3>
    </div>
    <div class="col-6 align-self-center"></div>
  </div>
  <div class="border-top mt-3 mb-4"></div>
  <div class="col-md-8 mt-3 mx-auto">
    <form (ngSubmit)="save()" [formGroup]="addForm" novalidate>
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        {{ error }}
      </div>
      <div class="form-group">
        <label class="d-block">Registration status</label>
        <b>{{ addForm.get('is_provisory').value ? 'Pending' : 'Approved' }}</b>
      </div>
      <div class="form-group">
        <label class="d-block">Name</label>
        <input type="text" class="form-control" formControlName="name" autocomplete="name" disabled />
      </div>
      <div class="form-group">
        <label class="d-block">Acronym</label>
        <input type="text" class="form-control" formControlName="acronym" autocomplete="acronym" disabled />
      </div>
      <div class="form-group">
        <label class="d-block">Country</label>
        <input type="text" class="form-control" formControlName="country" autocomplete="country" disabled />
      </div>

      <div class="form-group">
        <label class="d-block">Email</label>
        <input type="email" class="form-control" formControlName="email" autocomplete="email" email disabled />
      </div>

      <hr />
      <div class="form-group">
        <label class="d-block">Contact person</label>
        <input type="text" class="form-control" formControlName="contact_person" autocomplete="contact_person" />
      </div>

      <div class="form-group">
        <label class="d-block">Position</label>
        <input
          type="text"
          class="form-control"
          formControlName="contact_person_position"
          autocomplete="contact_person_position"
        />
      </div>

      <div class="form-group">
        <label class="d-block">Phone number</label>
        <input
          type="text"
          class="form-control"
          formControlName="contact_person_phone_number"
          autocomplete="contact_person_phone_number"
        />
      </div>

      <div class="form-group">
        <label class="d-block">Email</label>
        <input
          type="text"
          class="form-control"
          formControlName="contact_person_email"
          autocomplete="contact_person_email"
        />
      </div>

      <div class="form-group">
        <label class="d-block">Website</label>
        <input
          type="text"
          class="form-control"
          formControlName="contact_person_website"
          autocomplete="contact_person_website"
        />
      </div>

      <hr />

      <div class="form-group">
        <label class="d-block">Password</label>
        <input type="password" class="form-control" formControlName="password" autocomplete="current-password" />
        <span hidden translate>Password</span>
        <div class="pt-1"><small>*(Leave it blank if you don't want to change)</small></div>
      </div>
      <button class="btn btn-primary w-100" type="submit" [disabled]="addForm.invalid || isLoading">
        <span translate>Save</span>
      </button>
    </form>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<br /><br /><br />
