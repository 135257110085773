<div class="login-container bg-light">
  <div class="login-box">
    <h1 translate>
      <img src="assets/LOGO WGfLC 2025_22-26_V.png" width="150" />
    </h1>

    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h4 class="card-title text-center">
            <i class="far fa-3x fa-user-circle text-muted"></i>
          </h4>
          <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
            <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
              Email or password incorrect.
            </div>
            <div class="form-group">
              <label class="d-block">
                <input
                  type="text"
                  class="form-control"
                  formControlName="email"
                  autocomplete="email"
                  [placeholder]="'Email' | translate"
                />
                <span hidden translate>Email</span>
                <small
                  [hidden]="loginForm.controls.email.valid || loginForm.controls.email.untouched"
                  class="text-danger"
                  translate
                >
                  Email is required
                </small>
              </label>
              <label class="d-block">
                <input
                  type="password"
                  class="form-control"
                  formControlName="password"
                  autocomplete="current-password"
                  [placeholder]="'Password' | translate"
                  required
                />
                <span hidden translate>Password</span>
                <small
                  [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                  class="text-danger"
                  translate
                >
                  Password is required
                </small>
              </label>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" formControlName="remember" />
                  <span translate>Remember me</span>
                </label>
              </div>
            </div>
            <button class="btn btn-primary w-100" type="submit" [disabled]="loginForm.invalid || isLoading">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Login</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
