<div class="container mb-4">
  <div class="col-md-8 mt-3 mx-auto">
    <form (ngSubmit)="save()" [formGroup]="form" novalidate>
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        {{ error }}
      </div>
      <div class="form-group">
        <label class="d-block">Name *</label>
        <input type="text" class="form-control" formControlName="name" autocomplete="name" />
        <small [hidden]="form.controls.name.valid || form.controls.name.untouched" class="text-danger" translate>
          Name is required
        </small>
      </div>
      <div class="form-group">
        <label class="d-block">Responsible person *</label>
        <input
          type="text"
          class="form-control"
          formControlName="responsible_person"
          autocomplete="responsible_person"
        />
        <small
          [hidden]="form.controls.responsible_person.valid || form.controls.responsible_person.untouched"
          class="text-danger"
          translate
        >
          Responsible person is required
        </small>
      </div>
      <div class="form-group">
        <label class="d-block">Responsible phone *</label>
        <input type="text" class="form-control" formControlName="responsible_phone" autocomplete="responsible_phone" />
        <small
          [hidden]="form.controls.responsible_phone.valid || form.controls.responsible_phone.untouched"
          class="text-danger"
          translate
        >
          Responsible phone is required
        </small>
      </div>

      <div class="form-group">
        <label class="d-block">Responsible email *</label>
        <input type="email" class="form-control" formControlName="responsible_email" autocomplete="responsible_email" />
        <span hidden translate>Responsible email</span>
        <small
          [hidden]="form.controls.responsible_email.valid || form.controls.responsible_email.untouched"
          class="text-danger"
          translate
        >
          Invalid Responsible email
        </small>
      </div>

      <div class="form-group">
        <label class="d-block">Choreographer name</label>
        <input
          type="text"
          class="form-control"
          formControlName="choreographer_name"
          autocomplete="choreographer_name"
        />
      </div>

      <div class="form-group">
        <label class="d-block">Website</label>
        <input type="text" class="form-control" formControlName="website" autocomplete="website" />
      </div>

      <div class="form-group">
        <label class="d-block">Facebook</label>
        <input type="text" class="form-control" formControlName="facebook" autocomplete="facebook" />
      </div>

      <div class="form-group">
        <label class="d-block">Instagram</label>
        <input type="text" class="form-control" formControlName="instagram" autocomplete="instagram" />
      </div>

      <div class="form-group">
        <label class="d-block">Introduction</label>
        <textarea class="form-control" formControlName="introduction" autocomplete="introduction"></textarea>
      </div>

      <div class="form-group">
        <label class="d-block">Fun facts</label>
        <textarea class="form-control" formControlName="fun_facts" autocomplete="fun_facts"></textarea>
      </div>

      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Upload insurance declaration file</span>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="uploadFile($event)" />
            <label class="custom-file-label" for="inputGroupFile01">{{
              insuranceDeclarationFile ? insuranceDeclarationFile.name : 'Choose file'
            }}</label>
          </div>
        </div>
        <!--div class="pt-1"><small>*(Max file size: 5Mb)</small></div-->
      </div>
      <div class="form-group" *ngIf="insurance_declaration_path">
        <a href="{{ insurance_declaration_path }}" class="mr-2" target="_blank">Open insurance declaration file</a>
        <a href="javascript:void(0)" (click)="deleteInsuranceFile()" class="text-danger"><i class="fa fa-trash"></i></a>
      </div>

      <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid || isLoading">
        <span translate>Save</span>
      </button>
    </form>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
