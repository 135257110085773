<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
</div>
<div class="modal-body text-center">
  <div class="pb-4">
    {{ description }}
  </div>
  <div>
    <button type="submit" class="btn btn-success rounded px-4 mb-3 mr-3 text-center" (click)="yes()">Yes</button>
    <button type="submit" class="btn btn-danger rounded px-4 mb-3 text-center" (click)="no()">No</button>
  </div>
</div>
