import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DataService } from '@app/@helpers/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/@shared';
import { ToastService } from '@app/@shared/toast/toast.service';
import { AppService } from '@app/app.service';
import { FederationService } from '@app/federation/federation.service';
import { GroupProvisoryService } from '../group-provisory.service';

@Component({
  selector: 'app-group-provisory-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class GroupProvisoryListComponent implements OnInit {
  federation_id: any;
  federation_name: any;
  is_provisory_registration: boolean = true;
  data: any;
  toasts: any[] = [];
  config: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public credentialsService: CredentialsService,
    private groupsService: GroupProvisoryService,
    private federationService: FederationService,
    private dataService: DataService,
    private ngbModal: NgbModal,
    private toastService: ToastService,
    private appService: AppService
  ) {
    route.params.subscribe((val) => {
      this.federation_id = this.credentialsService.federation_id;
      //alert(this.federation_id);
      if (this.credentialsService.usertype == 'ADMIN') {
        this.federation_id = this.dataService.getByKey('federation_id');
        this.federation_name = '(' + this.dataService.getByKey('federation_name') + ')';
      }

      if (this.federation_id) {
        this.federationService.detail({ federation_id: this.federation_id }).subscribe((res: any) => {
          console.log('federation detail', res);
          this.is_provisory_registration = res.data.is_provisory;
        });
      }

      this.list();
    });
  }

  ngOnInit() {
    this.config = this.appService.config();
    console.log('CONFIG', this.config);
    // Only admin has access to this page
    // if(this.credentialsService.isAuthenticated() && this.credentialsService.usertype !== 'ADMIN'){
    //   this.router.navigate(['/'], { replaceUrl: true });
    // }
  }

  list() {
    this.groupsService.groupsList({ federation_id: this.federation_id }).subscribe((res: any) => {
      this.data = res.data;
      console.log(this.data);
    });
  }

  add() {
    this.router.navigateByUrl('/group-provisory/add');
  }

  edit(item: any) {
    this.dataService.setByKey('group_name', item.name);
    this.router.navigate(['/group-provisory/edit/' + item.id]);
  }

  delete(id: any) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Confirm';
    modalRef.componentInstance.description = 'Are you sure you want to delete?';
    modalRef.result.then((result) => {
      if (result) {
        this.groupsService.delete(id).subscribe((res: any) => {
          this.data = res.data;
          this.toastService.show('Deleted sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.list();
        });
      }
    });
  }

  get usertype(): string | null {
    return this.credentialsService.usertype;
  }

  back() {
    this.router.navigate(['/federation']);
  }
}
