<header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/home" routerLinkActive="active" translate>
      <img src="assets/logo2.svg" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      aria-controls="navbar-menu"
      aria-label="Toggle navigation"
      (click)="toggleMenu()"
      [attr.aria-expanded]="!menuHidden"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav">
        <a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">
          <!--i class="fas fa-home"></i-->
          <span translate>Home</span>
        </a>
        <a
          class="nav-item nav-link text-uppercase"
          routerLink="/federation"
          routerLinkActive="active"
          *ngIf="usertype == 'ADMIN'"
        >
          <!--i class="fas fa-question-circle"></i-->
          <span translate>Federations</span>
        </a>

        <a
          class="nav-item nav-link text-uppercase"
          routerLink="/group-provisory/"
          routerLinkActive="active"
          *ngIf="usertype == 'FEDERATION' && is_provisory_registration"
        >
          <!--i class="fas fa-question-circle"></i-->
          <span translate>Groups</span>(Provisional Registration)
        </a>

        <a
          class="nav-item nav-link text-uppercase"
          routerLink="/groups/"
          routerLinkActive="active"
          *ngIf="usertype == 'FEDERATION' && !is_provisory_registration"
        >
          <!--i class="fas fa-question-circle"></i-->
          <span translate>Groups</span>
        </a>

        <a
          class="nav-item nav-link text-uppercase"
          routerLink="/my-profile/"
          routerLinkActive="active"
          *ngIf="usertype == 'FEDERATION'"
        >
          <!--i class="fas fa-question-circle"></i-->
          <span translate>Federation Profile</span>
        </a>
      </div>
      <div class="navbar-nav ml-auto">
        <!--app-language-selector inNavbar="true" menuClass="dropdown-menu dropdown-menu-right"></app-language-selector-->
        <div class="nav-item" ngbDropdown>
          <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
            <i class="fas fa-user-circle"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">
              <span translate>Logged in as</span>
              <b>{{ username }}</b>
            </h6>
            <button class="dropdown-item" *ngIf="usertype == 'FEDERATION'" (click)="myProfile()" translate>
              My profile
            </button>

            <div class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="logout()" translate>Logout</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
