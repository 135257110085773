import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-athletes',
  templateUrl: './list-athletes.component.html',
  styleUrls: ['./list-athletes.component.scss'],
})
export class ListAthletesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
