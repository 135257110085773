<div class="container mb-4">
  <div class="col-md-8 mt-3 mx-auto">
    <h4 class="my-4">World Gym for life challenge</h4>

    <form (ngSubmit)="save()" [formGroup]="form" novalidate>
      <input type="hidden" formControlName="group_id" />
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        {{ error }}
      </div>
      <div class="form-group">
        <label class="d-block">Status</label>
        <select class="form-control" formControlName="state" autocomplete="state">
          <option
            *ngFor="let item of config?.status | keyvalue"
            value="{{ item.key }}"
            [disabled]="
              (usertype == 'ADMIN' && item.key == 'TBCLOC') ||
              (usertype == 'FEDERATION' && (item.key == 'TBCNF' || item.key == 'FINISHED'))
            "
            >{{ item.value }}</option
          >
        </select>
        <small [hidden]="form.controls.state.valid || form.controls.state.untouched" class="text-danger" translate>
          Status is required
        </small>
      </div>

      <div class="form-group">
        <label class="d-block">Title of the show *</label>
        <input type="text" class="form-control" formControlName="performance_title" autocomplete="performance_title" />
        <small
          [hidden]="form.controls.performance_title.valid || form.controls.performance_title.untouched"
          class="text-danger"
          translate
        >
          Performance title is required
        </small>
      </div>
      <div class="form-group">
        <label class="d-block">Category</label>
        <select class="form-control" formControlName="category" autocomplete="category">
          <option value=""></option>
          <option *ngFor="let item of config?.event_category | keyvalue" value="{{ item.key }}">{{
            item.value
          }}</option>
        </select>
        <small
          [hidden]="form.controls.category.valid || form.controls.category.untouched"
          class="text-danger"
          translate
        >
          Category is required
        </small>
      </div>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Upload Music file (MP3)</span>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="uploadFile($event)" />
            <label class="custom-file-label" for="inputGroupFile01">{{ mp3File ? mp3File.name : 'Choose file' }}</label>
          </div>
        </div>
        <div class="pt-1"><small>*(Max file size: 10Mb)</small></div>
      </div>
      <div class="form-group" *ngIf="performance_mp3_path">
        <a href="{{ performance_mp3_path }}" class="mr-2" target="_blank">Play MP3</a>
        <a href="javascript:void(0)" (click)="deleteMp3()" class="text-danger"><i class="fa fa-trash"></i></a>
      </div>

      <div class="form-check form-group">
        <input type="checkbox" class="form-check-input" id="equipmentRequired" formControlName="request_equipment" />
        <label class="form-check-label" for="equipmentRequired">Equipment required?</label>
      </div>

      <div *ngIf="form.value.request_equipment">
        <div class="form-group row" *ngFor="let item of config?.wgflc_equipment_defination; let i = index">
          <label for="equipment{{ i }}" class="col-sm-8 col-form-label col-form-label-sm">{{ item.label }}</label>
          <div class="col-sm-4">
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="equipment_{{ item.id }}"
              id="equipment{{ i }}"
              placeholder="Qty"
            />

            <small
              [hidden]="form.controls['equipment_' + item.id].valid || form.controls['equipment_' + item.id].untouched"
              class="text-danger"
              translate
            >
              Invalid quantity
            </small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="d-block">Detail info</label>
        <textarea
          class="form-control"
          formControlName="detailed_info_equipment"
          autocomplete="detailed_info_equipment"
        ></textarea>
      </div>

      <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid || isLoading">
        <span translate>Save</span>
      </button>
    </form>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
