import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { GroupsService } from '../groups.service';
import { DataService } from '@app/@helpers/data.service';
import { ToastService } from '@app/@shared/toast/toast.service';
import { finalize } from 'rxjs/operators';
import { untilDestroyed } from '@app/@core';
import { AppService } from '@app/app.service';
import { ConfirmModalComponent } from '@app/@shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss'],
})
export class ParticipantsComponent implements OnInit {
  @Input() group_id: any;
  error: any;
  form!: FormGroup;
  isLoading = false;
  obj: any;
  mp3File: any;
  performance_mp3_path: any;
  config: any;
  equipment_request: any;
  status_arr: any;
  usertype: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private groupsService: GroupsService,
    private appService: AppService,
    private toastService: ToastService,
    private ngbModal: NgbModal
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.config = this.appService.config();
      console.log(this.config);

      this.usertype = this.credentialsService.usertype;

      for (let formModule of this.config.wgflc_equipment_defination) {
        // and validators for max value
        this.form.addControl('equipment_' + formModule.id, new FormControl('', Validators.max(formModule.max_items)));
      }

      this.form.patchValue({ group_id: this.group_id });
      this.detail();
    });
  }

  detail() {
    this.groupsService.eventRegistrationDetail({ group_id: this.group_id }).subscribe((res: any) => {
      if (res.success) {
        console.log(res);
        this.form.patchValue({
          performance_title: res.data.performance_title,
          category: res.data.category,
          request_equipment: res.data.request_equipment,
          detailed_info_equipment: res.data.detailed_info_equipment,
          state: res.data.state,
        });

        this.performance_mp3_path = res.data.performance_mp3_path;

        if (res.data.equipment_request && res.data.equipment_request.length > 0) {
          for (let i = 0; i < res.data.equipment_request.length; i++) {
            let field_id = 'equipment_' + res.data['equipment_request'][i]['equipment_id'];
            console.log(field_id + ' - ' + res.data.equipment_request[i]['qty']);
            this.form.patchValue({ [field_id]: res.data.equipment_request[i]['qty'] });
          }
        }
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      group_id: ['', Validators.required],
      performance_title: ['', Validators.required],
      category: ['', Validators.required],
      request_equipment: [''],
      detailed_info_equipment: [''],
      state: ['', Validators.required],
    });
  }

  uploadFile(event: any) {
    this.mp3File = null;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log(file);
      if (file.type == 'audio/mpeg') {
        this.mp3File = file;
      } else {
        this.toastService.show('Invalid file type.', { classname: 'bg-danger text-light', delay: 5000 });
      }
    }
  }

  save() {
    this.isLoading = true;
    let formData = this.form.value;

    //let save$ = this.groupsService.editEventRegistration(formData);
    //if(this.group_id){
    let save$ = this.groupsService.editEventRegistration(this.mp3File, formData);
    //}

    save$
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.detail();
          this.toastService.show('Saved sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.error = false;
        } else {
          this.error = res.message;
        }
      });
  }

  deleteMp3() {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Confirm';
    modalRef.componentInstance.description = 'Are you sure you want to delete?';
    modalRef.result.then((result) => {
      if (result) {
        this.groupsService.deleteEventMp3({ group_id: this.group_id, event_type: 'WGFLC' }).subscribe((res: any) => {
          this.performance_mp3_path = '';
          this.toastService.show('Deleted sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
        });
      }
    });
  }

  ngOnDestroy() {}
}
