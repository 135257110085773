import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadAtheleteComponent } from './upload-athelete/upload-athelete.component';
import { ToastService } from '@app/@shared/toast/toast.service';
import { GroupsService } from '../groups.service';
import { ActivatedRoute } from '@angular/router';
import { AddAthleteComponent } from './add-athlete/add-athlete.component';
import { AppService } from '@app/app.service';
import { ConfirmModalComponent } from '@app/@shared';

@Component({
  selector: 'app-athlete-registration',
  templateUrl: './athlete-registration.component.html',
  styleUrls: ['./athlete-registration.component.scss'],
})
export class AthleteRegistrationComponent implements OnInit {
  @Input() group_id: any;
  data: any;
  config: any;
  constructor(
    private appService: AppService,
    private ngbModal: NgbModal,
    private toastService: ToastService,
    private groupsService: GroupsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.list();
    });

    this.config = this.appService.config();
    console.log(this.config);
  }

  list() {
    this.groupsService.atheletesList({ group_id: this.group_id }).subscribe((res: any) => {
      this.data = res.data;
      console.log(this.list);
    });
  }

  showUploadModal() {
    const modalRef = this.ngbModal.open(UploadAtheleteComponent);
    modalRef.componentInstance.group_id = this.group_id;
    modalRef.result.then((res: any) => {
      console.log('From lost ');
      console.log(res);
      if (typeof res == 'object') {
        if (res.success) {
          this.list();
          this.toastService.show(res.message, { classname: 'bg-success text-light', delay: 5000 });
        } else {
          this.toastService.show(res.message, { classname: 'bg-danger text-light', delay: 5000 });
        }
      }
    });
  }

  showAddEditModal(athlete_id: any) {
    const modalRef = this.ngbModal.open(AddAthleteComponent, { size: 'lg' });
    modalRef.componentInstance.group_id = this.group_id;
    modalRef.componentInstance.athlete_id = athlete_id;
    modalRef.result.then((res: any) => {
      console.log('From lost ');
      console.log(res);
      if (typeof res == 'object') {
        if (res.success) {
          this.list();
          //this.toastService.show(res.message, { classname: 'bg-success text-light', delay: 5000 });
        } else {
          this.toastService.show(res.message, { classname: 'bg-danger text-light', delay: 5000 });
        }
      }
    });
  }

  delete(id: any) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Confirm';
    modalRef.componentInstance.description = 'Are you sure you want to delete?';
    modalRef.result.then((result) => {
      if (result) {
        this.groupsService.deleteAthlete(id).subscribe((res: any) => {
          this.data = res.data;
          this.toastService.show('Deleted sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.list();
        });
      }
    });
  }
}
