import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CredentialsService } from '@app/auth';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private credentialsService: CredentialsService, private http: HttpClient) {}

  groupsList(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=list', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  addGroup(file: any, context: any) {
    let formData: FormData = new FormData();
    if (typeof file !== 'undefined') {
      formData.append('file', file, file.name);
    }

    formData.append('data', JSON.stringify(context));

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
      },
    };

    return this.http.post(environment.serverUrl + 'group?action=add-group', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );

    // let headers = { headers : { 'Authorization': 'Bearer '+this.credentialsService.credentials.api_token } };
    // let httpBody = new HttpParams({fromObject:context})

    // return this.http.post(environment.serverUrl+'group?action=add-group',httpBody, headers).pipe(
    //   tap((res: any) => {
    //     return res;
    //   })
    // );
  }

  editGroup(id: any, file: any, context: any) {
    context.id = id;
    console.log('From service', file);
    let formData: FormData = new FormData();
    if (typeof file !== 'undefined') {
      formData.append('file', file, file.name);
    }

    formData.append('data', JSON.stringify(context));

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
      },
    };

    return this.http.post(environment.serverUrl + 'group?action=edit-group', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );

    // let headers = { headers : { 'Authorization': 'Bearer '+this.credentialsService.credentials.api_token } };
    // let httpBody = new HttpParams({fromObject:context})

    // return this.http.post(environment.serverUrl+'group?action=edit-group',httpBody, headers).pipe(
    //   tap((res: any) => {
    //     return res;
    //   })
    // );
  }

  groupDetail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=group-detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  isGroupOwner(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=is_group_owner', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  uploadAtheleteRegistrations(group_id: any, file: any) {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('group_id', group_id);
    // const httpBody = new HttpParams()
    //   .set('uploadFile', file);

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
        //'Content-Type': 'multipart/form-data',
        //'Accept': 'application/json'
      },
    };

    return this.http.post(environment.serverUrl + 'group?action=upload-athlete-registrations', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  delete(id: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams().set('id', id);

    return this.http.post(environment.serverUrl + 'group?action=delete-group', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  atheletesList(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=ahtlete-list', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  addAthlete(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });

    return this.http.post(environment.serverUrl + 'group?action=add-athlete', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  editAthlete(id: any, context: any) {
    context.id = id;
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });

    return this.http.post(environment.serverUrl + 'group?action=edit-athlete', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  athleteDetail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=athlete-detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  deleteAthlete(id: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams().set('id', id);

    return this.http.post(environment.serverUrl + 'group?action=delete-athlete', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  editEventRegistration(file: any, data: any) {
    let formData: FormData = new FormData();
    if (typeof file !== 'undefined') {
      formData.append('file', file, file.name);
    }

    formData.append('data', JSON.stringify(data));
    // const httpBody = new HttpParams()
    //   .set('uploadFile', file);

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
        //'Content-Type': 'multipart/form-data',
        //'Accept': 'application/json'
      },
    };

    return this.http.post(environment.serverUrl + 'group?action=edit-event-registration', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  eventRegistrationDetail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=event-registration-detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  deleteEventMp3(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });

    return this.http.post(environment.serverUrl + 'group?action=delete-event-mp3', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  cityPerformanceDetail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=city-performance-detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  editCityPerformance(file: any, data: any) {
    let formData: FormData = new FormData();
    if (typeof file !== 'undefined') {
      formData.append('file', file, file.name);
    }

    formData.append('data', JSON.stringify(data));

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
      },
    };

    return this.http.post(environment.serverUrl + 'group?action=edit-city-performance', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  workshopDetail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group?action=workshop-detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  editWorkshops(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });

    return this.http.post(environment.serverUrl + 'group?action=edit-workshops', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  deleteGroupInsuranceFile(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });

    return this.http.post(environment.serverUrl + 'group?action=delete-group-insurance-file', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
}
