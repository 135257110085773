import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CredentialsService } from '@app/auth';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FederationService {
  constructor(private credentialsService: CredentialsService, private http: HttpClient) {}

  list() {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    return this.http.get(environment.serverUrl + 'federation?action=list', headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  add(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams()
      .set('name', context.name)
      .set('acronym', context.acronym)
      .set('country', context.country)
      .set('contact_person', context.contact_person)
      .set('contact_person_position', context.contact_person_position)
      .set('contact_person_phone_number', context.contact_person_phone_number)
      .set('contact_person_email', context.contact_person_email)
      .set('contact_person_website', context.contact_person_website)
      .set('is_provisory', context.is_provisory)
      .set('email', context.email)
      .set('password', context.password);

    return this.http.post(environment.serverUrl + 'federation?action=add', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  edit(id: any, context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams()
      .set('id', id)
      .set('name', context.name)
      .set('acronym', context.acronym)
      .set('country', context.country)
      .set('contact_person', context.contact_person)
      .set('contact_person_position', context.contact_person_position)
      .set('contact_person_phone_number', context.contact_person_phone_number)
      .set('contact_person_email', context.contact_person_email)
      .set('contact_person_website', context.contact_person_website)
      .set('is_provisory', context.is_provisory)
      .set('email', context.email)
      .set('password', context.password);

    return this.http.post(environment.serverUrl + 'federation?action=edit', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  editprofile(id: any, context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams()
      .set('id', id)
      .set('contact_person', context.contact_person)
      .set('contact_person_position', context.contact_person_position)
      .set('contact_person_phone_number', context.contact_person_phone_number)
      .set('contact_person_email', context.contact_person_email)
      .set('contact_person_website', context.contact_person_website)
      .set('is_provisory', context.is_provisory)
      .set('password', context.password);

    return this.http.post(environment.serverUrl + 'federation?action=edit', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  delete(id: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams().set('id', id);

    return this.http.post(environment.serverUrl + 'federation?action=delete', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  detail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'federation?action=detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  getReport(action: any, context: any) {
    const headers = {
      headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token },
      //params: new HttpParams({fromObject: context}),
      responseType: 'blob' as const,
    };

    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'report?action=' + action, httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
}
