import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/i18n';

import { Shell } from '@app/shell/shell.service';
import { GroupProvisoryListComponent } from './list/list.component';
import { GroupProvisoryAddComponent } from './add/add.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'group-provisory', component: GroupProvisoryListComponent, data: { title: extract('Groups') } },
    { path: 'group-provisory/add', component: GroupProvisoryAddComponent, data: { title: extract('Add group') } },
    { path: 'group-provisory/edit/:id', component: GroupProvisoryAddComponent, data: { title: extract('Edit group') } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class GroupProvisoryRoutingModule {}
