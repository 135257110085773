import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@core';
import { CredentialsService } from '@app/auth';
import { FederationService } from '../federation.service';
import { DataService } from '@app/@helpers/data.service';
import { ToastService } from '@app/@shared/toast/toast.service';

@Component({
  selector: 'app-add-federation',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent implements OnInit, OnDestroy {
  federation_id: any;
  error: any;
  addForm!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private federationService: FederationService,
    private dataService: DataService,
    private toastService: ToastService
  ) {
    this.createForm();

    // Only admin has access to this page
    if (this.credentialsService.isAuthenticated() && this.credentialsService.usertype !== 'ADMIN') {
      this.router.navigate(['/'], { replaceUrl: true });
    }

    this.federation_id = this.route.snapshot.paramMap.get('id');
    //alert("hia"+this.federation_id);
  }

  ngOnInit() {
    //alert("hia"+this.federation_id);
    this.route.params.subscribe((val) => {
      this.detail();
    });

    //   let id = this.route.snapshot.paramMap.get('id');
    //   this.route.queryParams.subscribe(params => {
    //     // this.firstname = params["firstname"];
    //     // this.lastname = params["lastname"];
    //     console.log(params);
    // });

    //this.load(id);
    //alert("editing => "+id);
  }

  ngOnDestroy() {}

  detail() {
    if (this.federation_id) {
      this.federationService.detail({ federation_id: this.federation_id }).subscribe((res: any) => {
        if (res.success) {
          this.addForm.patchValue({
            name: res.data.name,
            acronym: res.data.acronym,
            country: res.data.country,
            contact_person: res.data.contact_person,
            is_provisory: res.data.is_provisory,
            email: res.data.email,
          });
        }
      });
    }
  }

  back() {
    this.router.navigate(['/federation'], { replaceUrl: true });
  }

  save() {
    this.isLoading = true;

    let save$ = this.federationService.add(this.addForm.value);
    if (this.federation_id) {
      save$ = this.federationService.edit(this.federation_id, this.addForm.value);
    }

    save$
      .pipe(
        finalize(() => {
          this.addForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        if (res.success) {
          this.toastService.show('Saved sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.router.navigate([this.route.snapshot.queryParams.redirect || '/federation'], { replaceUrl: true });
        } else {
          this.error = res.message;
          //this.toastService.show(this.error, { classname: 'bg-danger text-light', delay: 5000 });
        }
      });
  }

  private createForm() {
    this.addForm = this.formBuilder.group({
      name: ['', Validators.required],
      acronym: ['', Validators.required],
      country: ['', Validators.required],
      contact_person: ['', ''],
      is_provisory: [true, ''],
      email: ['', Validators.email],
      password: ['', ''],
    });
  }
}
