import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CredentialsService } from '@app/auth';
import { GroupsService } from '../groups.service';
import { DataService } from '@app/@helpers/data.service';
import { finalize } from 'rxjs/operators';
import { untilDestroyed } from '@app/@core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '@app/@shared/toast/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/@shared';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  @Input() group_id: any;
  federation_id: any;
  error: any;
  form!: FormGroup;
  isLoading = false;
  obj: any;
  insuranceDeclarationFile: any;
  insurance_declaration_path: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private groupsService: GroupsService,
    private dataService: DataService,
    private toastService: ToastService,
    private ngbModal: NgbModal
  ) {
    this.createForm();
    this.federation_id = this.credentialsService.federation_id;

    if (this.credentialsService.usertype == 'ADMIN') {
      this.federation_id = this.dataService.getByKey('federation_id');
      //alert(this.federation_id);
    }
  }

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.groupDetail();
    });
  }

  groupDetail() {
    this.groupsService.groupDetail({ group_id: this.group_id }).subscribe((res: any) => {
      if (res.success) {
        console.log('group details inside grtoup', res);
        this.form.patchValue({
          name: res.data.name,
          responsible_person: res.data.responsible_person,
          responsible_phone: res.data.responsible_phone,
          responsible_email: res.data.responsible_email,
          choreographer_name: res.data.choreographer_name,
          website: res.data.website,
          facebook: res.data.facebook,
          instagram: res.data.instagram,
          introduction: res.data.introduction,
          fun_facts: res.data.fun_facts,
        });

        this.insurance_declaration_path = res.data.insurance_declaration;
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      responsible_person: ['', Validators.required],
      responsible_phone: ['', Validators.required],
      responsible_email: ['', [Validators.required, Validators.email]],
      choreographer_name: [''],
      website: [''],
      facebook: [''],
      instagram: [''],
      introduction: [''],
      fun_facts: [''],
    });
  }

  save() {
    this.isLoading = true;
    let formData = this.form.value;
    formData.federation_id = this.federation_id;
    let save$ = this.groupsService.addGroup(this.insuranceDeclarationFile, formData);
    if (this.group_id) {
      save$ = this.groupsService.editGroup(this.group_id, this.insuranceDeclarationFile, formData);
    }

    save$
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.router.navigateByUrl('/groups/edit/' + res.data.group_id + '#group');
          this.toastService.show('Saved sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.groupDetail();
        } else {
          this.error = res.message;
        }
      });
  }

  uploadFile(event: any) {
    this.insuranceDeclarationFile = null;
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];

      console.log(file.type);
      if (
        file.type == 'application/pdf' ||
        file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type == 'application/msword' ||
        file.type == 'application/zip'
      ) {
        this.insuranceDeclarationFile = file;
      } else {
        this.toastService.show('Invalid file type.', { classname: 'bg-danger text-light', delay: 5000 });
      }
    }
  }

  deleteInsuranceFile() {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Confirm';
    modalRef.componentInstance.description = 'Are you sure you want to delete?';
    modalRef.result.then((result) => {
      if (result) {
        this.groupsService.deleteGroupInsuranceFile({ group_id: this.group_id }).subscribe((res: any) => {
          this.insurance_declaration_path = '';
          this.toastService.show('Deleted sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
        });
      }
    });
  }

  ngOnDestroy() {}
}
