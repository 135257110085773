<div class="mt-4">
  <div class="row pb-4">
    <div class="col-sm-12 text-right">
      <button class="btn btn-success mr-2" (click)="showAddEditModal('')">Add manually</button>
      <button class="btn btn-success" (click)="showUploadModal()">Add via Excel file</button>
    </div>
  </div>
</div>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Family Name</th>
        <th scope="col">First Name</th>
        <th scope="col">Year of birth</th>
        <th scope="col">Accomodation</th>
        <th scope="col">Sleeping Items</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data; index as i">
        <th scope="row">{{ i + 1 }}</th>
        <td class="text-nowrap">{{ item.family_name }}</td>
        <td class="text-nowrap">{{ item.first_name }}</td>
        <td class="text-nowrap">{{ item.date_of_birth }}</td>
        <td class="text-nowrap">{{ item.acommodation_type }}</td>
        <td class="text-nowrap">{{ item.sleeping_items }}</td>
        <td class="text-nowrap">
          <button class="btn btn-primary btn-sm" (click)="showAddEditModal(item.id)"><i class="fa fa-edit"></i></button>
          <button class="btn btn-danger btn-sm" (click)="delete(item.id)"><i class="fa fa-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
