import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [LoaderComponent, ConfirmModalComponent, ToastComponent],
  exports: [LoaderComponent, ToastComponent],
})
export class SharedModule {}
