<div class="container mb-4">
  <div class="col-md-8 mt-3 mx-auto">
    <form (ngSubmit)="save()" [formGroup]="form" novalidate>
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        {{ error }}
      </div>
      <div class="form-group">
        <label class="d-block">Name *</label>
        <input type="text" class="form-control" formControlName="name" autocomplete="name" />
        <small [hidden]="form.controls.name.valid || form.controls.name.untouched" class="text-danger" translate>
          Mandatory field
        </small>
      </div>

      <!--   category: ['', Validators.required],
      accommodation: ['', Validators.required],
      show_performances: [''],
      workshops: [''], -->

      <div class="form-group">
        <label class="d-block">Estimated participants *</label>
        <input type="text" class="form-control" formControlName="n_particip" autocomplete="n_particip" />
        <small
          [hidden]="form.controls.n_particip.valid || form.controls.n_particip.untouched"
          class="text-danger"
          translate
        >
          Mandatory field
        </small>
      </div>

      <div class="form-group">
        <label class="d-block">Category *</label>
        <select class="form-control" formControlName="category" autocomplete="category">
          <option value=""></option>
          <option *ngFor="let item of config?.event_category | keyvalue" value="{{ item.key }}">{{
            item.value
          }}</option>
        </select>
        <small
          [hidden]="form.controls.category.valid || form.controls.category.untouched"
          class="text-danger"
          translate
        >
          Mandatory field
        </small>
      </div>

      <div class="form-group">
        <label class="d-block">Accommodation *</label>
        <select class="form-control" formControlName="accommodation" autocomplete="accommodation">
          <option value=""></option>
          <option *ngFor="let item of config?.accommodation | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
        </select>
        <small
          [hidden]="form.controls.accommodation.valid || form.controls.accommodation.untouched"
          class="text-danger"
          translate
        >
          Mandatory field
        </small>
      </div>

      <div class="form-check form-group">
        <input type="checkbox" class="form-check-input" id="show_performances" formControlName="show_performances" />
        <label class="form-check-label" for="show_performances">Show Performances?</label>
      </div>

      <div class="form-check form-group" *ngIf="false">
        <input type="checkbox" class="form-check-input" id="workshops" formControlName="workshops" />
        <label class="form-check-label" for="workshops">Workshops?</label>
      </div>

      <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid || isLoading">
        <span translate>Save</span>
      </button>
    </form>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
