<div class="full-container">
  <div class="col-md-11">
    <div style="margin-left: 30px;">
      <div class="row pb-4">
        <div class="col-sm-8">
          <h3 translate>
            Groups {{ federation_name }}
            <b style="text-decoration: underline;" *ngIf="is_provisory_registration">(Pending Registration)</b>
          </h3>
        </div>
        <div class="col-sm-4 align-self-center">
          <div class="float-right">
            <button class="btn btn-info mr-2" (click)="back()" *ngIf="usertype == 'ADMIN'">Back</button>
            <button class="btn btn-success" (click)="add()">Add new</button>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">#Estimated participants</th>
              <th scope="col">Category</th>
              <th scope="col">Accommodation</th>
              <th scope="col">Sleeping items</th>
              <th scope="col">Show performances</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data; index as i">
              <th scope="row">{{ i + 1 }}</th>
              <td class="text-nowrap">{{ item.name }}</td>
              <td class="text-nowrap">{{ item.n_particip }}</td>
              <td class="text-nowrap">{{ this.config.event_category[item.category] }}</td>
              <td class="text-nowrap">{{ this.config.acommodation_type[item.accommodation] }}</td>
              <td class="text-nowrap">{{ this.config.sleeping_items[item.sleeping_items] }}</td>
              <td class="text-nowrap">{{ item.show_performances === 1 ? 'YES' : 'NO' }}</td>
              <td class="text-nowrap">
                <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                <button class="btn btn-danger btn-sm" (click)="delete(item.id)"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
