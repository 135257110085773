import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@app/@helpers/data.service';
import { CredentialsService } from '@app/auth';
import { GroupsService } from '../groups.service';
import { ToastService } from '@app/@shared/toast/toast.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent implements OnInit {
  group_id: any;
  detailObj: any;
  federation_id: any;
  fragment = 'group';
  links = [
    { title: 'Group', fragment: 'group' },
    { title: 'Event Registration', fragment: 'event_registration' },
    { title: 'Participant Registration', fragment: 'participant-registration' },
    { title: 'Show Performances', fragment: 'show-performances' },
    { title: 'Workshops', fragment: 'workshops' },
  ];

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private dataService: DataService,
    private credentialsService: CredentialsService,
    private groupsService: GroupsService,
    private toastService: ToastService
  ) {
    this.group_id = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '';
    //alert(this.group_id);

    this.federation_id = this.credentialsService.federation_id;

    if (this.credentialsService.usertype == 'ADMIN') {
      this.federation_id = this.dataService.getByKey('federation_id');
    } else {
      if (this.group_id) {
        this.groupsService
          .isGroupOwner({ federation_id: this.federation_id, group_id: this.group_id })
          .subscribe((res: any) => {
            if (!res.success) {
              //alert("not a owner.. go away");
              this.toastService.show('Group not found', { classname: 'bg-danger text-light', delay: 5000 });
              this.router.navigate(['/groups'], { replaceUrl: true });
            }
          });
      }
    }
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
      if (!this.fragment) {
        this.fragment = 'group';
      }
      this.groupDetail();
    });
  }

  groupDetail() {
    this.groupsService.groupDetail({ group_id: this.group_id }).subscribe((res: any) => {
      if (res.success) {
        this.detailObj = res.data;
      }
    });
  }

  back() {
    this.router.navigate(['/groups'], { replaceUrl: true });
  }
}
