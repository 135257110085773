import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { Credentials, CredentialsService } from './credentials.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } };
  constructor(private credentialsService: CredentialsService, private http: HttpClient) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: any) {
    const httpBody = new HttpParams().set('email', context.email).set('password', context.password);

    return this.http.post(environment.serverUrl + 'auth?action=login', httpBody, this.headers).pipe(
      tap((res: any) => {
        if (res.success) {
          const data = {
            username: res.data.username,
            type: res.data.type,
            api_token: res.data.api_token,
            federation_id: res.data.federation_id,
          };
          //console.log("seeting data");
          //        console.log(data);
          this.credentialsService.setCredentials(data, context.remember);
        }

        return res;
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }
}
