import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';

import { FederationRoutingModule } from './federation-routing.module';
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [ListComponent, AddComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    FederationRoutingModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    NgbModule,
    I18nModule,
  ],
})
export class FederationModule {}
