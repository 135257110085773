import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { AppService } from '@app/app.service';
import { GroupsService } from '@app/groups/groups.service';
import { finalize } from 'rxjs/operators';
import { untilDestroyed } from '@app/@core';
import { ToastService } from '@app/@shared/toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-athlete',
  templateUrl: './add-athlete.component.html',
  styleUrls: ['./add-athlete.component.scss'],
})
export class AddAthleteComponent implements OnInit {
  @Input() group_id: any;
  @Input() athlete_id: any;
  config: any;
  error: any;
  form!: FormGroup;
  isLoading = false;
  obj: any;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private groupsService: GroupsService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.config = this.appService.config();
    this.form.patchValue({ group_id: this.group_id });
    this.form.patchValue({ meals_1: '' });
    /*this.form.patchValue({ meals_2: '' });
    this.form.patchValue({ meals_3: '' });
    this.form.patchValue({ meals_4: '' });
    this.form.patchValue({ meals_5: '' });*/
    this.form.patchValue({ traveling_arrival_transf_order: '' });
    //this.form.patchValue({'traveling_date_arrival': ''});
    //this.form.patchValue({'traveling_time_arrival': ''});
    this.form.patchValue({ traveling_flight_number: '' });
    this.form.patchValue({ traveling_departure_transfer_order: '' });
    // this.form.patchValue({'traveling_date_departure': ''});
    // this.form.patchValue({'traveling_time_departure': ''});
    this.form.patchValue({ traveling_departure_flight_number: '' });

    this.route.params.subscribe((val) => {
      this.athleteDetail();
    });

    console.log(this.config);
  }

  athleteDetail() {
    this.groupsService.athleteDetail({ athlete_id: this.athlete_id }).subscribe((res: any) => {
      if (res.success) {
        console.log(res);
        this.form.patchValue({
          family_name: res.data.family_name,
          first_name: res.data.first_name,
          date_of_birth: res.data.date_of_birth,
          gender: res.data.gender,
          passport: res.data.passport,
          nationality: res.data.nationality,
          wheelchair_user: res.data.wheelchair_user,
          group_position: res.data.group_position,
          acommodation_type: res.data.acommodation_type,
          acommodation_type_room: res.data.acommodation_type_room,
          sleeping_items: res.data.sleeping_items,
          insurance_type: res.data.insurance_type,
          insurance_policy_number: res.data.insurance_policy_number,
          traveling_arrival_transf_order: res.data.traveling_arrival_transf_order,
          traveling_date_arrival: res.data.traveling_date_arrival,
          traveling_time_arrival: res.data.traveling_time_arrival,
          traveling_flight_number: res.data.traveling_flight_number,
          traveling_departure_transfer_order: res.data.traveling_departure_transfer_order,
          traveling_date_departure: res.data.traveling_date_departure,
          traveling_time_departure: res.data.traveling_time_departure,
          traveling_departure_flight_number: res.data.traveling_departure_flight_number,
          meals_1: res.data.meals_1,
          /*meals_2: res.data.meals_2,
          meals_3: res.data.meals_3,
          meals_4: res.data.meals_4,
          meals_5: res.data.meals_5,*/
        });
      }
    });
  }

  validateOptionalDate(control: AbstractControl) {
    console.log(control);
    if (!(control.value instanceof Object) && control.value !== null) {
      console.log('from inside', control);
      control.patchValue(null, {
        onlySelf: true,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: true,
      });
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      group_id: ['', Validators.required],
      family_name: ['', Validators.required],
      first_name: ['', Validators.required],
      date_of_birth: ['', Validators.required],
      gender: ['', Validators.required],
      passport: ['', Validators.required],
      nationality: ['', Validators.required],
      wheelchair_user: ['', Validators.required],
      group_position: ['', Validators.required],
      acommodation_type: ['', Validators.required],
      acommodation_type_room: [''],
      sleeping_items: [''],
      insurance_type: ['', Validators.required],
      insurance_policy_number: ['', Validators.required],
      meals_1: [],
      /*meals_2: [],
      meals_3: [],
      meals_4: [],
      meals_5: [],*/
      traveling_arrival_transf_order: [],
      traveling_date_arrival: null,
      traveling_time_arrival: null,
      traveling_flight_number: [],
      traveling_departure_transfer_order: [],
      traveling_date_departure: null,
      traveling_time_departure: null,
      traveling_departure_flight_number: [],
    });
  }

  save() {
    this.isLoading = true;
    //this.form.value
    let save$ = this.groupsService.addAthlete(this.form.value);
    if (this.athlete_id) {
      save$ = this.groupsService.editAthlete(this.athlete_id, this.form.value);
    }

    save$
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.activeModal.close(res);
          this.isLoading = false;
          this.toastService.show('Saved sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
        } else {
          this.error = res.message;
          this.toastService.show(this.error, { classname: 'bg-danger text-light', delay: 5000 });
        }
      });
  }

  ngOnDestroy() {}
}
