import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { Shell } from '@app/shell/shell.service';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  Shell.childRoutes([{ path: 'my-profile', component: ProfileComponent, data: { title: extract('My profile') } }]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ProfileRoutingModule {}
