import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';

import { Shell } from '@app/shell/shell.service';
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'federation', component: ListComponent, data: { title: extract('Federations') } },
    { path: 'federation/add', component: AddComponent, data: { title: extract('Add federation') } },
    { path: 'federation/edit/:id', component: AddComponent, data: { title: extract('Edit federation') } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FederationRoutingModule {}
