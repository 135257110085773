import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';

import { Shell } from '@app/shell/shell.service';
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'groups', component: ListComponent, data: { title: extract('Groups') } },
    { path: 'groups/add', component: AddComponent, data: { title: extract('Add group') } },
    { path: 'groups/edit/:id', component: AddComponent, data: { title: extract('Edit group') } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class GroupsRoutingModule {}
