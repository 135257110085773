<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Upload Excel file</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <input type="file" (change)="uploadFile($event)" style="display: none;" #file />

  <button class="btn btn-primary" (click)="selectFile($event)" [disabled]="isLoading">
    <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
    <span *ngIf="!isLoading">Upload File</span>
    <span *ngIf="isLoading">Uploading. please wait!</span>
  </button>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
