<div class="container">
  <div class="row pb-4">
    <div class="col-sm-6 mb-2">
      <h3 translate>Federations</h3>
    </div>
    <div class="col-sm-6 align-self-center">
      <div class="float-right">
        <a href="javascript:void(0)" class="btn btn-info mb-2" (click)="downloadReport(-1, 'logistic_information')"
          >Report: logistic information</a
        >
        <button class="btn btn-success mb-2" (click)="add()">Add new</button>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Acronym</th>
          <th scope="col">Country</th>
          <th scope="col"># of groups</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ item.name }} {{ item.is_provisory == 1 ? ' (Provisory registration)' : '' }}</td>
          <td>{{ item.acronym }}</td>
          <td>{{ item.country }}</td>
          <td class="text-nowrap">{{ item.no_of_groups }}</td>
          <td class="text-nowrap">
            <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit"></i></button>
            <!--button class="btn btn-danger btn-sm" (click)="delete(item.id)"><i class="fa fa-trash"></i></button-->
            <button class="btn btn-info btn-sm" (click)="groupList(item)" *ngIf="usertype == 'ADMIN'">Groups</button>

            <div class="btn-group mr-3">
              <div
                class="btn-group"
                ngbDropdown
                container="body"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <button class="btn btn-sm btn-primary" ngbDropdownToggle>Reports</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button ngbDropdownItem (click)="downloadReport(item, 'logistic_information')">
                    Logistic information
                  </button>
                  <button ngbDropdownItem (click)="downloadReport(item, 'accommodation_food_transport_info')">
                    Accommodation/Food & Transport Info
                  </button>
                  <button ngbDropdownItem (click)="downloadReport(item, 'world_gym_for_life_contest_information')">
                    World Gym for Life Contest Information
                  </button>
                  <button ngbDropdownItem (click)="downloadReport(item, 'show_performances_information')">
                    Show Performances Information
                  </button>
                  <button ngbDropdownItem (click)="downloadReport(item, 'musics_contest')">Musics Contest</button>
                  <button ngbDropdownItem (click)="downloadReport(item, 'musics_show_performances')">
                    Musics Show Performances
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
