import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@app/@helpers/data.service';
import { CredentialsService } from '@app/auth';
import { ToastService } from '@app/@shared/toast/toast.service';
import { GroupProvisoryService } from '../group-provisory.service';

@Component({
  selector: 'app-add-group-provisory',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class GroupProvisoryAddComponent implements OnInit {
  group_id: any;
  detailObj: any;
  federation_id: any;
  fragment = 'group-provisory';

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private dataService: DataService,
    private credentialsService: CredentialsService,
    private groupsService: GroupProvisoryService,
    private toastService: ToastService
  ) {
    this.group_id = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '';

    //alert(this.group_id);

    this.federation_id = this.credentialsService.federation_id;
    //alert(this.federation_id);

    if (this.credentialsService.usertype == 'ADMIN') {
      this.federation_id = this.dataService.getByKey('federation_id');
    } else {
      if (this.group_id) {
        this.groupsService
          .isGroupOwner({ federation_id: this.federation_id, group_id: this.group_id })
          .subscribe((res: any) => {
            if (!res.success) {
              this.toastService.show('Group not found', { classname: 'bg-danger text-light', delay: 5000 });
              this.router.navigate(['/group-provisory'], { replaceUrl: true });
            }
          });
      }
    }
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
      if (!this.fragment) {
        this.fragment = 'group-provisory';
      }
      this.groupDetail();
    });
  }

  groupDetail() {
    this.groupsService.groupDetail({ group_id: this.group_id }).subscribe((res: any) => {
      if (res.success) {
        this.detailObj = res.data;
      }
    });
  }

  back() {
    this.router.navigate(['/group-provisory'], { replaceUrl: true });
  }
}
