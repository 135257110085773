import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';

import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { SharedModule } from '@app/@shared';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { AddComponent } from '@app/federation/add/add.component';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    ProfileRoutingModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    NgbModule,
    I18nModule,
  ],
  entryComponents: [AddComponent],
})
export class ProfileModule {}
