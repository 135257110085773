import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { GroupsService } from '@app/groups/groups.service';
import { UploadService } from '@app/@helpers/upload.service';
import { ToastService } from '@app/@shared/toast/toast.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-athelete',
  templateUrl: './upload-athelete.component.html',
  styleUrls: ['./upload-athelete.component.scss'],
})
export class UploadAtheleteComponent implements OnInit {
  @ViewChild('file') fileEle: ElementRef;
  @Input() group_id: any;
  isLoading = false;
  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  addFiles() {
    //alert)
  }

  // At the file input element
  // (change)="selectFile($event)"
  selectFile(event: any) {
    this.fileEle.nativeElement.click();
  }

  uploadFile(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log(file);
      if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //this.uploadService.uploadFile(url, file).subscribe()
        this.isLoading = true;
        this.groupsService.uploadAtheleteRegistrations(this.group_id, file).subscribe((res: any) => {
          console.log(res);
          this.activeModal.close(res);
          this.isLoading = false;
        });
        console.log(file);
      } else {
        this.toastService.show('Invalid file type.', { classname: 'bg-danger text-light', delay: 5000 });
      }
    }
  }
}
