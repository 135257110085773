import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}

  set(data: any) {
    sessionStorage.setItem('data', JSON.stringify(data));
  }

  get() {
    return JSON.parse(sessionStorage.getItem('data'));
  }

  delete() {
    sessionStorage.removeItem('data');
  }

  setByKey(key: any, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getByKey(key: any) {
    return JSON.parse(sessionStorage.getItem(key));
  }
}
