<div class="container">
  <div class="row">
    <div class="col-6">
      <h3 translate>Add Federation</h3>
    </div>
    <div class="col-6 align-self-center">
      <button class="btn btn-info float-right" (click)="back()"><i class="fa fa-chevron-left"></i> Back</button>
    </div>
  </div>
  <div class="border-top mt-3 mb-4"></div>
  <div class="col-md-8 mt-3 mx-auto">
    <form (ngSubmit)="save()" [formGroup]="addForm" novalidate>
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        {{ error }}
      </div>
      <div class="form-group">
        <label class="d-block">Name *</label>
        <input type="text" class="form-control" formControlName="name" autocomplete="name" />
        <span hidden translate>Name</span>
        <small [hidden]="addForm.controls.name.valid || addForm.controls.name.untouched" class="text-danger" translate>
          Name is required
        </small>
      </div>
      <div class="form-group">
        <label class="d-block">Acronym *</label>
        <input type="text" class="form-control" formControlName="acronym" autocomplete="acronym" />
        <span hidden translate>Acronym</span>
        <small
          [hidden]="addForm.controls.acronym.valid || addForm.controls.acronym.untouched"
          class="text-danger"
          translate
        >
          Acronym is required
        </small>
      </div>
      <div class="form-group">
        <label class="d-block">Country *</label>
        <input type="text" class="form-control" formControlName="country" autocomplete="country" />
        <span hidden translate>Country*</span>
        <small
          [hidden]="addForm.controls.country.valid || addForm.controls.country.untouched"
          class="text-danger"
          translate
        >
          Country is required
        </small>
      </div>

      <div class="form-group">
        <label class="d-block">Email</label>
        <input type="email" class="form-control" formControlName="email" autocomplete="email" email />
        <span hidden translate>Email</span>
      </div>

      <div class="form-group">
        <label class="d-block">Contact person</label>
        <input type="text" class="form-control" formControlName="contact_person" autocomplete="contact_person" />
      </div>

      <div class="form-group">
        <label class="d-block">Password</label>
        <input type="password" class="form-control" formControlName="password" autocomplete="current-password" />
        <span hidden translate>Password</span>
      </div>

      <!-- is provisory -->
      <div class="form-group">
        <label class="d-block">Is Provisory</label>
        <input type="checkbox" class="form-control" formControlName="is_provisory" />
        <span hidden translate>Is Provisory</span>
      </div>

      <button class="btn btn-primary w-100" type="submit" [disabled]="addForm.invalid || isLoading">
        <span translate>Save</span>
      </button>
    </form>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<br /><br /><br />
