import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CredentialsService } from '@app/auth';
import { tap } from 'rxjs/operators';
import { DataService } from './@helpers/data.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  data: any;
  constructor(private http: HttpClient, private dataService: DataService) {}

  loadSiteConfig() {
    return this.http.get(environment.serverUrl).pipe(
      tap((res: any) => {
        this.dataService.setByKey('config', JSON.stringify(res.data));
      })
    );
  }

  config() {
    return JSON.parse(this.dataService.getByKey('config'));
  }
}
