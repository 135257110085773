import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService extends NgbDateParserFormatter {
  readonly DELIMITER = '/';
  parse(value: string): NgbDateStruct {
    console.log(value);
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date === null) {
      return null;
    }
    console.log('formmating');
    console.log(date);

    return [date.day < 10 ? '0' + date.day : date.day, date.month < 10 ? '0' + date.month : date.month, date.year].join(
      this.DELIMITER
    );

    //return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}
