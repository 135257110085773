import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CredentialsService } from '@app/auth';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupProvisoryService {
  constructor(private credentialsService: CredentialsService, private http: HttpClient) {}

  groupsList(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group_provisory?action=list', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  addGroup(context: any) {
    let formData: FormData = new FormData();

    formData.append('data', JSON.stringify(context));

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
      },
    };

    return this.http.post(environment.serverUrl + 'group_provisory?action=add-group', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );

    // let headers = { headers : { 'Authorization': 'Bearer '+this.credentialsService.credentials.api_token } };
    // let httpBody = new HttpParams({fromObject:context})

    // return this.http.post(environment.serverUrl+'group?action=add-group',httpBody, headers).pipe(
    //   tap((res: any) => {
    //     return res;
    //   })
    // );
  }

  editGroup(id: any, context: any) {
    context.id = id;
    let formData: FormData = new FormData();

    formData.append('data', JSON.stringify(context));

    let headers = {
      headers: {
        Authorization: 'Bearer ' + this.credentialsService.credentials.api_token,
      },
    };

    return this.http.post(environment.serverUrl + 'group_provisory?action=edit-group', formData, headers).pipe(
      tap((res) => {
        return res;
      })
    );

    // let headers = { headers : { 'Authorization': 'Bearer '+this.credentialsService.credentials.api_token } };
    // let httpBody = new HttpParams({fromObject:context})

    // return this.http.post(environment.serverUrl+'group?action=edit-group',httpBody, headers).pipe(
    //   tap((res: any) => {
    //     return res;
    //   })
    // );
  }

  groupDetail(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group_provisory?action=group-detail', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  isGroupOwner(context: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    let httpBody = new HttpParams({ fromObject: context });
    return this.http.post(environment.serverUrl + 'group_provisory?action=is_group_owner', httpBody, headers).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  delete(id: any) {
    let headers = { headers: { Authorization: 'Bearer ' + this.credentialsService.credentials.api_token } };
    const httpBody = new HttpParams().set('id', id);

    return this.http.post(environment.serverUrl + 'group_provisory?action=delete-group', httpBody, headers).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
}
