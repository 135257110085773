<div class="container">
  <div class="row pb-4">
    <div class="col-sm-8">
      <h3 translate>
        Groups {{ federation_name }}
        <b style="text-decoration: underline;" *ngIf="is_provisory_registration">(Pending Registration)</b>
      </h3>
    </div>
    <div class="col-sm-4 align-self-center">
      <div class="float-right">
        <button class="btn btn-info mr-2" (click)="back()" *ngIf="usertype == 'ADMIN'">Back</button>
        <a href="javascript:void(0)" class="btn btn-info" (click)="downloadReport(-1, 'logistic_information')"
          >Report: logistic information</a
        >
        <button class="btn btn-success" (click)="add()">Add new</button>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Code</th>
          <th scope="col">Name of the group</th>
          <th scope="col">Number of participants</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td class="text-nowrap">{{ item.code }}</td>
          <td class="text-nowrap">{{ item.name }}</td>
          <td class="text-nowrap">{{ item.no_of_participants }}</td>
          <td class="text-nowrap">{{ this.config.status[item.status] }}</td>
          <td class="text-nowrap">
            <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger btn-sm" (click)="delete(item.id)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
