<div class="container">
  <div class="row">
    <div class="col-6">
      <h3 *ngIf="!group_id">Add group</h3>
      <h3 *ngIf="group_id && detailObj">Edit group {{ detailObj.name }}</h3>
    </div>
    <div class="col-6 align-self-center">
      <button class="btn btn-info float-right" (click)="back()"><i class="fa fa-chevron-left"></i> Back</button>
    </div>
  </div>
  <div class="border-top mt-3 mb-4"></div>

  <router-outlet></router-outlet>
  <div *ngIf="fragment == 'group-provisory'">
    <app-group-provisory group_id="{{ group_id }}"></app-group-provisory>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
