import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from '@app/@core';
import { DataService } from '@app/@helpers/data.service';
import { ToastService } from '@app/@shared/toast/toast.service';
import { CredentialsService } from '@app/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { GroupProvisoryService } from '../group-provisory.service';
import { AppService } from '@app/app.service';
@Component({
  selector: 'app-group-provisory',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupProvisoryComponent implements OnInit {
  @Input() group_id: any;
  federation_id: any;
  error: any;
  form!: FormGroup;
  isLoading = false;
  obj: any;
  insuranceDeclarationFile: any;
  insurance_declaration_path: any;
  config: any;
  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private groupsService: GroupProvisoryService,
    private dataService: DataService,
    private toastService: ToastService,
    private ngbModal: NgbModal
  ) {
    this.createForm();
    this.federation_id = this.credentialsService.federation_id;

    this.config = this.appService.config();
    console.log(this.config);

    if (this.credentialsService.usertype == 'ADMIN') {
      this.federation_id = this.dataService.getByKey('federation_id');
    }
  }

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.groupDetail();
    });
  }

  groupDetail() {
    this.groupsService.groupDetail({ group_id: this.group_id }).subscribe((res: any) => {
      if (res.success) {
        console.log('group details inside grtoup', res);

        this.form.patchValue({
          name: res.data.name,
          n_particip: res.data.n_particip,
          category: res.data.category,
          accommodation: res.data.accommodation,
          show_performances: res.data.show_performances,
          workshops: res.data.workshops,
        });
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      n_particip: ['', Validators.required],
      category: ['', Validators.required],
      accommodation: ['', Validators.required],
      show_performances: [''],
      workshops: [''],
    });
  }

  save() {
    this.isLoading = true;
    let formData = this.form.value;
    formData.federation_id = this.federation_id;
    let save$ = this.groupsService.addGroup(formData);
    if (this.group_id) {
      save$ = this.groupsService.editGroup(this.group_id, formData);
    }

    save$
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.router.navigateByUrl('/group-provisory');
          this.toastService.show('Saved sucessfully!', { classname: 'bg-success text-light', delay: 5000 });
          this.groupDetail();
        } else {
          this.error = res.message;
        }
      });
  }

  ngOnDestroy() {}
}
